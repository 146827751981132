import {
  Table,
  message,
  Button,
  Drawer,
  Select,
  Input,
  DatePicker,
  Form,
} from 'antd';
import { useEffect, useState } from 'react';
import { getAgentBody } from '../lib/apis';
import { useSelector } from 'react-redux';
import '../style/ball.css';
import dayjs from 'dayjs';

function AgentBallOngoing() {
  const { permissions, access_token, ui_size } = useSelector(
    (state) => state.user
  );
  const [loading, setLoading] = useState(false);
  const [today, setToday] = useState();
  const [deposit, setDeposit] = useState(0);
  const [maung, setMaung] = useState(0);
  const [totalMatch, setTotalMatch] = useState(0);
  const [lists, setLists] = useState();

  const columns = [
    {
      title: 'Logo',
      key: 'logo',
      render: (text, record) => (
        <>
          <img src={record.logo} style={{ width: '35px' }} />
        </>
      ),
    },
    {
      title: 'Name',
      key: 'name',
      render: (text, record) => (
        <>
          {record.name_en} <br />
          {record.name_mm}
        </>
      ),
    },
    {
      title: 'Body Deposits',
      key: 'bodyDeposit',
      render: (text, record) => <>{record.totalBitAmount}</>,
    },
  ];

  async function getOngoing(date) {
    setLoading(true);

    try {
      const res = await getAgentBody(
        `${process.env.REACT_APP_SERVER_API}/v1/agents/bet/ongoing-deposit?today=${date}`,
        access_token
      );
      const { depositBody, depositMaung, total, resultBody } = res.data;
      setDeposit(depositBody);
      setDeposit(depositMaung);
      setTotalMatch(total);
      console.log(res.data);
      setLists(resultBody);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function onFinish(value) {
    const { current } = value;
    let formatDate = current ? dayjs(current).format('YYYY-MM-DD') : today;
    getOngoing(formatDate);
    // console.log(formatDate);
  }

  useEffect(() => {
    const currentDate = dayjs().format('YYYY-MM-DD');
    setToday(currentDate);
    // console.log(currentDate);
    getOngoing(currentDate);
  }, []);

  //   console.log(lists);

  return (
    <>
      <div className="flex flex items-center justify-between ">
        <h4>Ongoing Match Lists</h4>
        <Form
          layout="inline"
          onFinish={onFinish}
          clear
          style={{ marginBottom: 16 }}
        >
          <Form.Item name="current">
            <DatePicker
              placeholder={today ? today : 'Select Date'}
              style={{ width: 200 }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </Form.Item>
        </Form>
      </div>

      <div>
        <p>
          Body Deposit : <span className="text-success ms-3">{deposit}</span>
        </p>
        <p>
          Maung Deposit : <span className="text-success ms-3">{maung}</span>
        </p>
        <p>
          Today Total Deposit :{' '}
          <span className="text-success ms-3">{maung + deposit}</span>
        </p>
        {/* <p>
          Today Matches :{' '}
          <span className="text-success ms-3">{totalMatch} ပွဲ</span>
        </p> */}
      </div>

      <Table
        loading={loading}
        columns={columns}
        dataSource={lists}
        rowKey={(record) => record.id}
      />
    </>
  );
}

export default AgentBallOngoing;
